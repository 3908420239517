
// Yes!  I know ... these should be variables, but these were added before variables existed!  (ang)
// login screen

@media(min-width: $screen-md) {

    header.login-header {
        background-size: contain;
        background-repeat: no-repeat;
    }	

}

// new student interface stuff
.romeo {

    @media(min-width: $screen-md) {

        header.login-header {
            background-size: contain;
            background-repeat: no-repeat;
        }	

        .main-banner-outer .main-banner-container .main-banner-txt {
            background-color: #000000ad;
            padding: .5rem 1rem;
            margin-left: 1.5rem;
        }

    }

}
